.MuiOutlinedInput-root {
    background-color: #FFF;
}


/* -- */

/* Animations not in use currently */
/* .fade-out {
    animation: fadeOutToNone .5s ease-out;
    opacity: 0;
}

.fade-in {
    animation: fadeInFromNone .5s ease-out;
    opacity: 1;
} */

#login-wrapper,
#login-container,
#login-form-container,
#login-hero-container {
    box-sizing: border-box;
}

#login-wrapper {
    /* background: linear-gradient(45deg, #ff8000 0%, #000000 100%); */
    /* background: linear-gradient(135deg, #01014f 0%, #7238ab 100%); */
    /* background: linear-gradient(135deg, #011D4F 0%, #3880AB 100%); */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

#login-container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: 550px;
    border-radius: 1rem;
    /* box-shadow: 2px 2px 2px #BBB; */
}

#login-form-container {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    background-color: #E7E7E7;
    padding: 48px;
    width: 30vw;
    max-width: 450px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .login-form  */
.login-form__welcome-icon {
    font-size: 4rem;
}

#login-form__header {
    text-align: center;
}

.login-form__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.login-form__actions--centered {
    justify-content: center;
}

#login-form h1 {
    margin-top: .5rem;
    font-size: 2.3rem;
    color: #222;
    font-weight: 600;
}

#engager-logo-for-no-branding {
    width: 80%;
}

.powered-by-container {
    text-align: center;
}

#login-hero .powered-by-container {
    margin-top: 5rem;
    display: block;
}

#login-form .powered-by-container {
    margin-top: 3rem;
    display: none;
}



#login-hero-container {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: #FFF;
    padding: 48px;
    width: 30vw;
    max-width: 500px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#login-hero {
    text-align: center;
}

#login-hero img {
    max-width: 100%;
    max-height: 250px;
}

#login-hero-practice-name {
    font-size: 3rem;
    font-variant: small-caps;
    font-family: "Helvetica Neue", "Roboto", "Arial", sans-serif;
}

@media screen and (max-width: 863px) {

    #login-container {
        max-width: 400px;
    }

    #login-form-container {
        padding: 2rem;
        order: 1;
        border-radius: 0;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        width: 100%;
        max-width: unset;
        min-width: unset;
    }

    .login-form__actions {
        flex-direction: column;
    }

    .login-form__action--secondary {
        order: 2;
    }

    #login-hero-container {
        padding: 2rem;
        order: 0;
        border-radius: 0;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        width: 100%;
        max-width: unset;
        min-width: unset;
    }

    #login-hero .powered-by-container {
        display: none;
    }

    #login-form .powered-by-container {
        display: block;
    }

    #login-hero img {
        max-width: unset;
        width: 60%;
        min-width: 200px;
        max-height: 200px;
    }
}

/* @keyframes fadeInFromNone {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOutToNone {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
} */